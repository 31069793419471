<template>
  <div class="contents" v-if="useYn == 0">
    <base-text-contents
        :infoTitle="textContents_attr.infoTitle"
        :text1="textContents_attr.text1">
      <!-- <div class="btnwrap" slot="slot_btns">
        <button type="button" @click="loadDoc('setup', null)" class="btn btn-block btn-primary btn-lg w200">신규 등록</button>
      </div> -->
    </base-text-contents>
  </div>
  <div class="contents" v-else-if="useYn == 1">
    <form>
      <!-- 스트랩핑 관리_사용여부 관리  -->
      <div class="card shadow">
        <div class="card-body">
          <!-- 스크랩핑 리스트 테이블 -->
          <div class="credit-table-block pl20 pt20 pb20 mb10">
            <base-select-box
                :class="selectBoxClass"
                :items="scrapGroupIds"
                v-model="scrapGroupIds"
                @toParent:selectValue="val => selectedGroupValue = val"
            />
            <!-- <base-select-box
              :class="selectBoxClass"
              :items="selectBoxItems2"/> -->
            <button type="button" class="btn btn-darkgrey ml15 mr20 w120" @click="getScrapList(selectedGroupValue)">조회</button>
          </div>
          <!-- Button -->
          <div class="tx_rt pb10">
            <button type="button" class="btn btn-block btn-primary w60 mr5" @click="setScrapSwitch(true)">사용</button>
            <button type="button" class="btn btn-block btn-grey w70" @click="setScrapSwitch(false)">미사용</button>
          </div>
          <table class="table01">
            <caption>금융기관 표</caption>
            <colgroup>
              <col style="width:50px">
              <col>
              <col style="width:200px">
              <col style="width:200px">
              <col style="width:200px">
            </colgroup>
            <thead>
            <tr>
              <th class="tx_cnt">
                <label><input type="checkbox" v-model="checkAll" @change="changeAllChecked($event.target.checked)"></label>
              </th>
              <th class="tx_cnt">스크랩핑 명</th>
              <th class="tx_cnt">대상구분</th>
              <th class="tx_cnt">사용여부</th>
              <th class="tx_cnt">시스템사용여부</th>
            </tr>
            </thead>
            <tbody v-if="scrapLstTableItems.items.length != 0">
            <tr v-for="(item, index) in scrapLstTableItems.items" :key="index">
              <td class="tx_cnt">
                <label><input type="checkbox" :value="index" :disabled="!item.scrapEnable" @change="changeChecked(index, item.checkBox)" :checked="item.checkBox"></label>
              </td>
              <td class="tx_cnt">{{ item.scrapName }}</td>
              <td class="tx_cnt">{{ item.targetKind }}</td>
              <td class="tx_cnt">
                <label class="switch">
                  <input type="checkbox" :value="item.scrapIdx" :disabled="!item.scrapEnable" :checked="item.scheduleEnable" @change="changeEnabled(index, item.scheduleEnable)">
                  <span class="slider round"></span>
                </label>
              </td>
              <td class="tx_cnt">{{ item.scrapUseYn }}</td>
            </tr>
            </tbody>
            <tbody v-if="scrapLstTableItems.items.length == 0">
              <tr>
                <td class="tx_cnt" colspan="5">조회된 데이터가 없습니다.</td>
              </tr>
            </tbody>
          </table>
          <!-- //스크랩핑 리스트 테이블 -->
        </div>
        <!-- Button -->
        <div class="btnwrap">
          <button type="button" class="btn btn-block btn-primary btn-lg w200" @click="putClientScrapList">저장</button>
        </div>
      </div>
      <!-- //스트랩핑 관리_사용여부 관리  -->
    </form>
  </div>
  <div class="contents" v-else>

  </div>
</template>
<script>

export default {
	name: 'PageScrapingUseMng',
	data: () => ({
		prefix:"api/scrap/manage",
		// Variables
		useYn: 2,				// 스크랩핑 사용여부관리 화면 허용여부
		textContents_attr: {
			infoTitle: '해당  화면이 허용되지 않았습니다.',
			text1: '연계시스템 > 공통설정에서 사용자별 스크랩핑 사용여부 관리 설정을 허용 으로  저장해주세요.',
			text2: '',
		},
		selectBoxClass: 'form-control form-control-inline w200 mr5',
		scrapGroupIds : [{key: '', value: '선택',}],		// 스크랩핑 셀렉트 박스 목록
		selectedGroupValue : '',						// 스크랩핑 셀렉트 박스 선택값 변수
		// scrapList
		scrapLstTableItems:{
			heads:[],
			items:[]
		},
		checkAll : false,				// scrapList head checkbox

	}),
	methods: {
		// 스크래핑사용여부관리 화면을 열어도 되는지 확인.
		openViewYn(){
			//this.useYn = 1;
			this.$cAxios.post("/api/linkedsys/common/getScrapUserManageYn")
			.then((response) => {

				this.useYn = response.data;
				//this.$emit('scrapUseYn',response.data);

			}).catch((error) => {
				console.log(error.response.data);
			})

		},
		// 스크랩핑설정  - selectBox
		searchScrapGroup(){
			this.$cAxios.post("/api/linkedsys/common/getScrapGroupList")
			.then((response) => {
				//console.log("log datalist", response.data);

				if(response.data.length > 0){
					for(var i=0; i<response.data.length; i++){
						var groupData ={
							value : response.data[i].name,
							key : response.data[i].id
						};
						this.scrapGroupIds.push(groupData);
					}
				}

			}).catch((error) => {
				console.log(error.response.data);
			})

		},
		getScrapList(selectedGroupId){
			// this.params = {}
			// this.header = {'Content-Type': 'application/json;'};
			// selectBox key가 있을 경우 검색 Go
			console.log("selectedGroupId",selectedGroupId);
			console.log("this.scrapGroupIds",this.scrapGroupIds);
			if(!selectedGroupId) return alert("스크랩핑 종류를 선택해 주세요");

			this.$cAxios.post(this.prefix + "/getClientScrapList", "groupId="+selectedGroupId)
			.then((response) => {
				// 내역초기화
				this.checkAll = false;
				this.scrapLstTableItems.items = [];

				if (response.data.length > 0) {
					for (var i=0; i<response.data.length; i++) {
						//console.log("get data", response.data[i]);
						// scrapIdx, scrapName, scrapEnable, targetKind
						this.obj = {};
						this.obj.scrapIdx = response.data[i].scrapIdx;
						this.obj.scrapName = response.data[i].scrapName;
						this.obj.targetKind = response.data[i].targetKind;
						this.obj.scrapEnable = response.data[i].scrapEnable;
						this.obj.clientScrapIdx = response.data[i].clientScrapIdx;
						this.obj.scheduleEnable = response.data[i].scheduleEnable;
						if(response.data[i].scrapEnable){
							this.obj.scrapUseYn = "허용";
						}else{
							this.obj.scrapUseYn = "비허용";
						}
						this.obj.checkBox = false;

						this.scrapLstTableItems.items.push(this.obj);
						//console.log("push data", this.scrapLstTableItems.items);
					}
				}
			}).catch((error) => {
				console.log(error.response.data);
			})

		},
		// 전체선택,해제 이벤트
		changeAllChecked(checkAll){
			//checkAll
			for(var i=0; i<this.scrapLstTableItems.items.length; i++){
				if(this.scrapLstTableItems.items[i].scrapEnable){	// 시스템사용여부가 허용일때만 체크 가능.
					this.scrapLstTableItems.items[i].checkBox = checkAll;
				}
			}

		},
		// 체크박스 선택할때 updateList set과 로직처리.
		changeChecked(chkScrapIdx, chkStatus){
			// updateScrapIdxLst put
			this.scrapLstTableItems.items[chkScrapIdx].checkBox = !chkStatus;	// 현재 상태값 반대 넣어놓기

		},
		// 사용여부 스위치 바꿀때 설정값 set
		changeEnabled(chkScrapIdx,chkStatus){
			this.scrapLstTableItems.items[chkScrapIdx].scheduleEnable = !chkStatus;

		},
		// 체크박스 선택 후 사용,미사용 버튼 이벤트
		setScrapSwitch(switchChk){
			for(var i = 0; i<this.scrapLstTableItems.items.length; i++){
				if(this.scrapLstTableItems.items[i].checkBox == true && this.scrapLstTableItems.items[i].scrapEnable == true){
					this.scrapLstTableItems.items[i].scheduleEnable = switchChk;
					this.scrapLstTableItems.items[i].checkBox = false;	// checkBox 초기화
				}
			}
			// head checkbox 초기화
			this.checkAll = false;
		},
		// 사용여부 설정 후 저장
		putClientScrapList(){
			// 사용여부 리스트 this.scrapLstTableItems
			//console.log("putClientScrapList ",this.scrapLstTableItems.items);

			this.header = {	'Content-Type': 'application/json;'	};
			this.$cAxios.put(this.prefix + "/setScrapLstEnable", this.scrapLstTableItems.items, this.header
			).then(() => {
				alert("스크랩핑 사용여부 설정이 변경되었습니다.");

			}).catch((error) => {
				alert(error.response.data);
				// 기존값 리로드
				this.getScrapList(this.selectedGroupValue);
			})
		}
	},
	mounted() {
		// selectBox 호출
		this.searchScrapGroup();
	},
	created(){
		// 인증정보 설정 상태에 따라 화면 허용할지  체크
		this.openViewYn();
	}
}
</script>